import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const NotFoundPage = ({ data }) => (
    <Layout>
        <article className="sheet">
            <div className="sheet__inner">
                <h1 className="sheet__title">Page Not Found</h1>
                <p className="sheet__lead">
                    <Link to="/">Return to Home</Link>
                </p>
            </div>
        </article>
    </Layout>
);

export default NotFoundPage;
